import React from 'react'

import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
import Select from 'react-select';
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';

import { Tooltip as ReactTooltip } from "react-tooltip";
import axios from "axios";
import Swal from 'sweetalert2'
import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import Moment from 'react-moment';
import download from 'downloadjs'
import Header from './Header';
import Left_menu from './Left_menu';
import { useNetworkState } from 'react-use';
import { CornerUpLeft } from 'lucide-react';
import Footer from '../Footer';

const Customer_warehouse = () => {
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
    const isOnline = useNetworkState();
    console.log("isONline  === " ,isOnline )
    const [defaultCountry, setDefaultCountry] = React.useState({country_id:0})
    const [selectedOption, setSelectedOption] = React.useState(null);
    const [options, setOptions] = React.useState([]);
    const [modalState , setModalState] = React.useState({show:false})
    const [btnState , setbtnState] = React.useState(true)

    const [countryList, setcountryList] = React.useState([]);
    const [stateList, setStateList] = React.useState([]);
    const [cityList, setCityList] = React.useState([]);
    const [pincodeList, setPincodeList] = React.useState([]);
    const[addressState, setaddressState] = React.useState([]);
    const [addstate,setaddState] = React.useState({full_name:"" , country_name:244 ,state_name:"" , city_name:"" , pincode:"" , address:"" , email:"" , mobile:"" , err:{} });
    // console.log("addstate ===== " , addstate)
    const[country,setCountry] = React.useState({country_id:""})
    const[state,setState] = React.useState({state_id:""})
    const[city,setCity] = React.useState({city_id:""})
    const[editmodal , seteditmodal] = React.useState({show:false , full_name:"" , country_name:"" ,state_name:"" , city_name:"" , pincode:"" , address:"" , email:"" , mobile:""   , address_id:""  , country_id:244 , state_id:"" , city_id:"" ,warehouse_name:"", err:{}})
    // console.log("editmodal===== " , editmodal)
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
React.useEffect(()=>{
    getCountryList()
    get_country_list()
    address_book_detail()
},[])
   
      
      const onChangeLocationstate = (e) => {
        // console.log("e general name ",e.target.name);
         // console.log("e general value ",e.target.value);
         
         
       let values =   Object.assign({},e.target.value.split(',') )
       // console.log("first",values[0])
       setState({state_id:values[0]})
       setaddState({...addstate , state_name:values[0]})
        getCityList({state_id:values[0], country_id:values[1]})
      
       
      }
      const onChangeLocationstateedit = (e) => {
        // console.log("e general name ",e.target.name);
         // console.log("e general value ",e.target.value);
         
         
       let values =   Object.assign({},e.target.value.split(',') )
       // console.log("first",values[0])
       setState({state_id:values[0]})
       seteditmodal({...editmodal , state_id:values[0], state_name:values[0] , city_id:"", city_name:"" , pincode:""})
       setCityList([])
       setOptions([])
       setSelectedOption(null)
        getCityList({state_id:values[0], country_id:values[1]})
      
       
      }
     
      const onChangeLocationcity = (e) => {
        // console.log("e general name ",e.target.name);
         // console.log("e general value ",e.target.value);
        setCity({city_id:e.target.value})
        setaddState({...addstate , city_name:e.target.value})
        setSelectedOption(null)
        setOptions([])
        getPincodeList(e.target.value)
      
       
      }
      const onChangeLocationcityedit = (e) => {
        // console.log("e general name ",e.target.name);
         // console.log("e general value ",e.target.value);
        setCity({city_id:e.target.value})
        seteditmodal({...editmodal , city_id:e.target.value , city_name:e.target.value})
        getPincodeList(e.target.value)
        setSelectedOption(null)
        setOptions([])
      
       
      }
      const getPincodeList = (value) => {
      
        // console.log("value",value)
        let url = config.apiUrl + '/country/pincode_list';
           let sendData = { city_id: value };
           // console.log("bb", sendData);
           axios.post(url, sendData, { headers: config.headers }).then((res) => {
               // // console.log("city  ", res);           
               setPincodeList(res.data.output)
               let data = res.data.output
               const transformedOptions = data.map(item => ({
                value: item.postal_code, // Set the value property based on your data
                label: item.postal_code // Set the label property based on your data
              }));
           setOptions(transformedOptions)
       
       
           }).catch((e) => {
       
       
           });
       }
      const getCityList = (value) => {
      
       // console.log("value",value)
       let url = config.apiUrl + '/country/city_list';
          let sendData = { state_id: value.state_id,country_id:value.country_id, limit: 1000 };
          // console.log("bb", sendData);
          axios.post(url, sendData, { headers: config.headers }).then((res) => {
              // // console.log("city  ", res);           
              setCityList(res.data.output)
      
      
          }).catch((e) => {
      
      
          });
      }
      const getCountryList = () => {


        let url = config.apiUrl + '/country/country_list';
        let sendData = {limit:300 , status:1};
        // // console.log("bb", sendData);
        axios.post(url, sendData, { headers: config.headers }).then((res) => {
            // // console.log("res ", res);           
            setcountryList(res.data.output)
    
    
        }).catch((e) => {
    
    
        });
    }
    const getStateList = (country_id) => {


        let url = config.apiUrl + '/country/state_list';
        let sendData = { t_country_id: country_id, limit: 1000 };
        //  // console.log("bb", sendData);
        axios.post(url, sendData, { headers: config.headers }).then((res) => {
            // // console.log("state ", res);           
            setStateList(res.data.output)
    
    
        }).catch((e) => {
    
    
        });
    }

    const handleChange =(e)=>{
       setaddState({...addstate, [e.target.name]:e.target.value , err:{}})
       
    }

    const addAddress = (value) => {
      setbtnState(false)
        // console.log("value",value)
        let url = config.apiUrl + '/country/add_address_book';
           let sendData = { addstate, customer_id: userData.customer_id };
           // console.log("bb", sendData);
           let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

           if ( re.test(addstate.email) ) {
           if(addstate.full_name!=""  && addstate.state_name!="" && addstate.city_name!="" && addstate.address!="" && addstate.pincode!="" && addstate.email!="" && addstate.mobile!=""  ){
           axios.post(url, sendData, { headers: config.headers }).then((res) => {
             if(res.data.status==true){
                setModalState({...modalState,show:false})
                setbtnState(true)
                setOptions([])
                setSelectedOption(null)
                address_book_detail()
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                  })
                  
                  Toast.fire({
                    background:"#001b72",
                    type: 'success',
                    title: "Address Added Successfully",
                    color:"white"
                  });
                
             }
             else{
              setbtnState(true)
             }
           }).catch((e) => {
            setModalState({...modalState,show:false})

            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              
              Toast.fire({
                background:"#c2200a",
                type: 'unsuccess',
                title: "Something Went Wrong",
                color:"white"
              });
              setbtnState(true)
           });
        }
        
        else{
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              
              Toast.fire({
                background:"#c2200a",
                type: 'unsuccess',
                title: "Please Fill All the Details",
                color:"white"
              });
              setbtnState(true)
        }
    }else{
        let itemsR = {...addstate};
        itemsR.err = {};
          itemsR.err["email"] = true;   
         setaddState(itemsR)
         setbtnState(true)
    }
       }

       const address_book_detail = ()=>{
        let url = config.apiUrl + '/country/address_book';
        let sendData = {  customer_id: userData.customer_id };
        // console.log("bb", sendData);
        axios.post(url,sendData ,{headers:config.headers}).then((res)=>{
            if(res.data.status==true){
                // console.log("sasdfdsa")
                setaddressState(res.data.output)
                setState([])
                setCityList([])
                setPincodeList([])
                setaddState({...addstate, full_name:"" ,  state_name:"" , city_name:"" , pincode:"" , address:"" , email:"" , mobile:"" ,  err:{} })
                setOptions([])

                 
            }

        }).catch(()=>{
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              
              Toast.fire({
                background:"#c2200a",
                type: 'unsuccess',
                title: "Something Went Wrong",
                color:"white"
              });
       
           
        })
       }

       const delete_address_book = (value)=>{
        let url = config.apiUrl + '/country/delete_address_book';
        let sendData = {  customer_id: userData.customer_id , address_id:value };
        axios.post(url , sendData ,{headers:config.headers}).then((res)=>{
            if(res.data.status==true){
                address_book_detail()
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                  })
                  
                  Toast.fire({
                    background:"#001b72",
                    type: 'success',
                    title: "Deleted Successfully",
                    color:"white"
                  });
            }

        }).catch(()=>{
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              
              Toast.fire({
                background:"#c2200a",
                type: 'unsuccess',
                title: "Something Went Wrong",
                color:"white"
              });
        })
       }

       const setAsPrimary =(value)=>{
        let url = config.apiUrl + '/country/update_address_book_primary_status';
        let sendData = {  customer_id: userData.customer_id , address_id:value };
        axios.post(url , sendData ,{headers:config.headers}).then((res)=>{
            if(res.data.status==true){
                address_book_detail()
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                  })
                  
                  Toast.fire({
                    background:"#001b72",
                    type: 'success',
                    title: "Update Successfully",
                    color:"white"
                  });
            }

        }).catch(()=>{
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              
              Toast.fire({
                background:"#c2200a",
                type: 'unsuccess',
                title: "Something Went Wrong",
                color:"white"
              });
        })
       }

       const handleEditChange = (event) => {
        // event.persist()
         // console.log("event is v",event.target.name);
         seteditmodal({
             ...editmodal,
             [event.target.name]: event.target.value,
         })
     }


     const Seteditaddress = async(sub)=>{
      // console.log("sub ==" , sub)
      seteditmodal({show:true, address_id:sub.address_id,full_name:sub.full_name , country_name:sub.country_name ,state_name:sub.state_name , city_name:sub.city_name , pincode:sub.pincode , address:sub.address , email:sub.email , mobile:sub.mobile , warehouse_name:sub.warehouse_id , country_id:sub.country_id , state_id:sub.state_id , city_id:sub.city_id })
      // getStateList(sub.country_id)
     
        try {
          // // console.log("value", value);
          let url = config.apiUrl + '/country/pincode_list';
          let sendData = { city_id: sub.city_id };
          // console.log("bb", sendData);
          
          const response = await axios.post(url, sendData, { headers: config.headers });
          
          // setPincodeList(response.data.output);
          let data = response.data.output;
          const transformedOptions = data.map(item => ({
            value: item.postal_code,
            label: item.postal_code
          }));
          // // console.log("transformedOptions == ", transformedOptions);
          setOptions(transformedOptions);
          const matchedOption = transformedOptions.find(option => option.value === sub.pincode);
          // // console.log("matchedOption",matchedOption)
          if (matchedOption) {
            setSelectedOption(matchedOption);
          }
          
       
        } catch (error) {
          // console.error(error);
          throw error; 
        }
      
   }

     const editAddress =()=>{
        // // console.log("value",value)
        let url = config.apiUrl + '/country/edit_address_book';
           let sendData = { editmodal, customer_id: userData.customer_id };
           // console.log("bb", sendData);
           let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

           if ( re.test(editmodal.email) ) {
           if(editmodal.full_name!="" && editmodal.country_name!="" && editmodal.state_name!="" && editmodal.city_name!="" && editmodal.address!="" && editmodal.pincode!="" && editmodal.email!="" && editmodal.mobile!=""  ){
           axios.post(url, sendData, { headers: config.headers }).then((res) => {
             if(res.data.status==true){
                seteditmodal({...editmodal,show:false})
                setSelectedOption(null);
                setOptions([])
                setState([]);
                setCityList([]);
                setPincodeList([]);
                setCountry({country_id:""});
                setState({state_id:""});
                setCity({city_id:""});
                address_book_detail()
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                  })
                  
                  Toast.fire({
                    background:"#001b72",
                    type: 'success',
                    title: "Address Updated Successfully",
                    color:"white"
                  });
                
             }
           }).catch((e) => {
            seteditmodal({...editmodal,show:false})

            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              
              Toast.fire({
                background:"#c2200a",
                type: 'unsuccess',
                title: "Something Went Wrong",
                color:"white"
              });
       
           });
        }
        
        else{
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              
              Toast.fire({
                background:"#c2200a",
                type: 'unsuccess',
                title: "Please Fill All the Details",
                color:"white"
              });
        }
    }else{
        let itemsR = {...editmodal};
        itemsR.err = {};
          itemsR.err["email"] = true;   
         seteditmodal(itemsR)
    }
       
     }

     const get_country_list = async (value) => {
        // const value = e.target.value;
         
         // console.log("value--------.....", value)
        
    
         let url = config.apiUrl + '/country/country_list';
     
         // // console.log("headers => ", config.headers);
     
         let senddata = {}
        
         axios.post(url, senddata, { headers: config.headers })
           .then((responseJson) => {
              // console.log("responseJson111 => ", responseJson.data);
        
             setDefaultCountry(responseJson.data.defaultCountry)
             setCountry({country_id:responseJson.data.defaultCountry})
             setaddState({...addstate , country_name:responseJson.data.defaultCountry})
               getStateList(responseJson.data.defaultCountry)
        
           })
           .catch((error) => {
             //Hide Loader
     
             // console.error(error);
           });
       };
       const handleSelectChange = selectedOption => {
        setSelectedOption(selectedOption);
        setaddState({...addstate, pincode:selectedOption})
      };
      const handleSelectChange1 = selectedOption => {
        setSelectedOption(selectedOption);
        seteditmodal({...editmodal, pincode:selectedOption})
      };

       const onhide=()=>{
        setModalState({ show: false })
        setaddState({...addstate, full_name:""  ,state_name:"" , city_name:"" , pincode:"" , address:"" , email:"" , mobile:"" , warehouse_name:"", err:{} })
         setSelectedOption(null)
        setOptions([])
        setState([])
        setCityList([])
        setPincodeList([])
        setCountry({country_id:""})
        setState({state_id:""})
        setCity({city_id:""})

       }
  return (
//     <div>
//         <Modal show={modalState.show} onHide={() => onhide()} size='lg'>
// <Modal.Header closeButton>
//                     <Modal.Title>
                   
//         <h4 class="modal-title">Create Warehouse</h4>
       
    
//                     </Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body >
//                 <div className='row'>
//                     <div className='col-6'>
//                         <label>Full Name <span class="text-danger">*</span></label>
//                         <input className='form-control mt-2' type='text' placeholder='Enter Full Name' name="full_name" autoComplete='off' onChange={(e)=>handleChange(e)}/>
//                     </div>
                  
//                 </div>
//                 <div className='row mt-3'>
                   
//                     <div className='col-3'>
//                     <label for="" class="form-label">Select State: <span class="text-danger">*</span></label>
//           <select class="form-select"  name="state_name" onChange={(e)=>onChangeLocationstate(e)}>
//             <option>Select</option>
//             {stateList.map((item)=>{
//             return (
//               <option value={[item.state_id,item.country_id]}>{item.state_name}</option>
//           )
           
// })}
          
//           </select>
//                     </div>
//                     <div className='col-3'>
//                     <label for="" class="form-label">Select City: <span class="text-danger">*</span></label>
//                     <select class="form-select" name="city_name" onChange={(e)=>onChangeLocationcity(e)}>
//             <option >Select</option>
//             {cityList.map((item)=>{
//             return (
//               <option value={item.city_id}>{item.city_name}</option>
//           )
           
// })}
//           </select>
//                     </div>
//                     <div className='col-3'>
//                     <label for="" class="form-label">Select Pincode: <span class="text-danger">*</span></label>
                  
      
//       <Select
      
//         options={options}
//         value={selectedOption}
//         onChange={(e)=>handleSelectChange(e)}
//         isClearable={true}
//         name="pincode"
//       />
           
          
      
//                     </div>

//                 </div>

//                 <div className='row mt-3'>
//                     <div className='col-12'>
//                         <label>Address <span class="text-danger">*</span></label>
//                         <input className='form-control mt-2' placeholder='Enter Address' type='text' name="address" autoComplete='off' onChange={(e)=>handleChange(e)}/>
//                     </div>
//                 </div>
//                 <div className='row mt-3'>
//                     <div className='col-6'>
//                         <label>Email <span class="text-danger">*</span></label>
//                         <input className='form-control mt-2' type='email' placeholder='Enter Email' name="email" autoComplete='off' onChange={(e)=>handleChange(e)} />
//                         {(addstate.err.email)?
//                             <>
//                             <i class="fa fa-exclamation-triangle" style={{color:"rgb(167, 37, 20)"}} aria-hidden="true"></i><small style={{color:"rgb(167, 37, 20)" ,fontSize: "0.75rem",
//                             lineHeight: "1.66667",
//                             fontWeight: "400",
//                             letterSpacing: "0.05em",marginLeft:"4px"}}>Please Fill Valid Email</small></>:""}
//                     </div>
//                     <div className='col-6'>
//                         <label>Mobile # <span class="text-danger">*</span></label>
//                         <input className='form-control mt-2' type='number' placeholder='Enter Mobile Number' name="mobile" autoComplete='off' onChange={(e)=>handleChange(e)}/>
//                     </div>
//                 </div>
//                 <hr/>
//       <div class="p-3 text-center">
//       {btnState==true?  <a  class="btn btn-primary" onClick={() => addAddress()}>Create Warehouse</a>:""}
//       </div>
//                 </Modal.Body>
               
//             </Modal> 
//             <Modal show={editmodal.show} onHide={() => seteditmodal({ show: false })} size='lg'>
// <Modal.Header closeButton>
//                     <Modal.Title>Edit Address</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body >
//                 <div className='row'>
//                     <div className='col-6'>
//                         <label>Full Name <span class="text-danger">*</span></label>
//                         <input className='form-control mt-2' type='text' placeholder='Enter Full Name' name="full_name" autoComplete='off' onChange={(e)=>handleEditChange(e)} value={editmodal.full_name}/>
//                     </div>
//                     <div className='col-6'>
//                         <label>Warehouse Name <span class="text-danger">*</span></label>
//                         <input className='form-control mt-2' type='text' placeholder='Enter Warehouse Name' name="warehouse_name" autoComplete='off' onChange={(e)=>handleEditChange(e)} disabled value={editmodal.warehouse_name}/>
//                     </div>
                  
//                 </div>
//                 <div className='row mt-3'>
                    
//                     <div className='col-3'>
//                     <label for="" class="form-label">Select State: <span class="text-danger">*</span></label>
//           <select class="form-select"  name="state_name" onChange={(e)=>onChangeLocationstateedit(e)} >
//             <option>{editmodal.state_name}</option>
//             {stateList.map((item)=>{
//             return (
//               <option value={[item.state_id,item.country_id]}>{item.state_name}</option>
//           )
           
// })}
          
//           </select>
//                     </div>
//                     <div className='col-3'>
//                     <label for="" class="form-label">Select City: <span class="text-danger">*</span></label>
//                     <select class="form-select" name="city_name" onChange={(e)=>onChangeLocationcityedit(e)} >
//             <option >{editmodal.city_name!=""?editmodal.city_name:"Select"}</option>
//             {cityList.map((item)=>{
//             return (
//               <option value={item.city_id}>{item.city_name}</option>
//           )
           
// })}
//           </select>
//                     </div>
//                     <div className='col-3'>
//                     <label for="" class="form-label">Select Pincode: <span class="text-danger">*</span></label>
//                     <Select
      
//         options={options}
//         value={selectedOption}
//         onChange={(e)=>handleSelectChange1(e)}
//         isClearable={true}
//         name="pincode"
//       />
//                     </div>

//                 </div>

//                 <div className='row mt-3'>
//                     <div className='col-12'>
//                         <label>Address <span class="text-danger">*</span></label>
//                         <input className='form-control mt-2' placeholder='Enter Address' type='text' name="address" autoComplete='off' onChange={(e)=>handleEditChange(e)} value={editmodal.address}/>
//                     </div>
//                 </div>
//                 <div className='row mt-3'>
//                     <div className='col-6'>
//                         <label>Email <span class="text-danger">*</span></label>
//                         <input className='form-control mt-2' type='email' placeholder='Enter Email' name="email" autoComplete='off' onChange={(e)=>handleEditChange(e)} value={editmodal.email}/>
                      
//                     </div>
//                     <div className='col-6'>
//                         <label>Mobile # <span class="text-danger">*</span></label>
//                         <input className='form-control mt-2' type='number' placeholder='Enter Mobile Number' name="mobile" autoComplete='off' onChange={(e)=>handleEditChange(e)} value={editmodal.mobile}/>
//                     </div>
//                 </div>
//                 </Modal.Body>
//                 <Modal.Footer>
//                 <Button variant="danger" onClick={() => editAddress()}>
//                         Submit
//                     </Button>
//                   <Button variant="primary" onClick={() => seteditmodal({ show: false })}>
//                         Close
//                     </Button>
                              
//                 </Modal.Footer>
//             </Modal>  
//     <Left_menu value={6}/>
//  <section class="home">
//         <Header/>
//         <section class="mb-3 mt-3 tools">
//         <div class="container">
//             <div class="row py-3">
//                 <div class="col-12 col-md">
//                     <h3>Warehouse</h3>
//                 </div>
//                 <div class="col-12 col-md-4 text-end">
//                     <a onClick={() => setModalState({ show: true })} class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#Warehouse_add">Create Warehouse</a>
//                 </div>
//             </div>
//             <div class="row warehouse">
//             {addressState.map((sub)=>(
//                 <div class="col-12 col-md-4 mb-3">
//                         <div class="card  shadow-sm active">
//                             <div class="card-body">
//                             {sub.primary==true?  <span class="badge rounded-pill bg-primary">Primary Address</span>:""}
//                             <h6 class="pt-3">{sub.warehouse_id}</h6>
//                             <small>{sub.address} , {sub.city_name} , {sub.state_name} , {sub.pincode}</small><br/>
//                             <small>Mobile: {sub.mobile}</small>

//                             <hr/>
//                             <ul class="nav justify-content-end">
//                                 <li class="nav-item">
//                                 <a class="nav-link text-secondary"  data-bs-toggle="tooltip" title="Edit address" style={{cursor:"pointer"}} onClick={()=>Seteditaddress(sub)}>
//                                         <i class="material-icons-outlined">drive_file_rename_outline</i>
//                                     </a>
//                                 </li>
//                                 <li class="nav-item">
//                                     <a class="nav-link text-danger" onClick={()=>delete_address_book(sub.address_id)} data-bs-toggle="tooltip" title="Delete address"><i class="material-icons-outlined">delete</i></a>
//                                 </li>
//                                 <li class="nav-item">
//                                 {sub.primary==false? <a class="nav-link" style={{cursor:"pointer"}} onClick={()=>setAsPrimary(sub.address_id)} data-bs-toggle="tooltip" title="Set as Primary Address"><i class="material-icons-outlined">favorite_border</i></a>:""}
//                             </li>
//                             </ul>
//                         </div>
//                     </div>
//                 </div>
//             ))}
//         </div>  
//         </div>
//         </section>
        
//     </section>

//     </div>
<div >
<Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className="home-section">
         
          <Header/>
    <Modal show={editmodal.show} onHide={() => seteditmodal({ show: false })} size='lg'>
 <Modal.Header closeButton>
                     <Modal.Title>Edit Address</Modal.Title>
                 </Modal.Header>
                 <Modal.Body >
                 <div className='row'>
                     <div className='col-6'>
                         <label>Full Name <span class="text-danger">*</span></label>
                         <input className='form-control mt-2' type='text' placeholder='Enter Full Name' name="full_name" autoComplete='off' onChange={(e)=>handleEditChange(e)} value={editmodal.full_name}/>
                     </div>
                     <div className='col-6'>
                         <label>Warehouse Name <span class="text-danger">*</span></label>
                         <input className='form-control mt-2' type='text' placeholder='Enter Warehouse Name' name="warehouse_name" autoComplete='off' onChange={(e)=>handleEditChange(e)} disabled value={editmodal.warehouse_name}/>
                     </div>
                  
                 </div>
                 <div className='row mt-3'>
                    
                     <div className='col-3'>
                     <label for="" class="form-label">Select State: <span class="text-danger">*</span></label>
           <select class="form-select"  name="state_name" onChange={(e)=>onChangeLocationstateedit(e)} >
             <option>{editmodal.state_name}</option>
             {stateList.map((item)=>{
             return (
               <option value={[item.state_id,item.country_id]}>{item.state_name}</option>
           )
           
 })}
          
           </select>
                     </div>
                     <div className='col-3'>
                     <label for="" class="form-label">Select City: <span class="text-danger">*</span></label>
                     <select class="form-select" name="city_name" onChange={(e)=>onChangeLocationcityedit(e)} >
             <option >{editmodal.city_name!=""?editmodal.city_name:"Select"}</option>
             {cityList.map((item)=>{
             return (
               <option value={item.city_id}>{item.city_name}</option>
           )
           
 })}
          </select>
                     </div>
                     <div className='col-3'>
                     <label for="" class="form-label">Select Pincode: <span class="text-danger">*</span></label>
                     <Select
      
        options={options}
         value={selectedOption}
         onChange={(e)=>handleSelectChange1(e)}
         isClearable={true}
         name="pincode"
       />
                     </div>

                 </div>

                 <div className='row mt-3'>
                     <div className='col-12'>
                         <label>Address <span class="text-danger">*</span></label>
                         <input className='form-control mt-2' placeholder='Enter Address' type='text' name="address" autoComplete='off' onChange={(e)=>handleEditChange(e)} value={editmodal.address}/>
                     </div>
                </div>
                 <div className='row mt-3'>
                     <div className='col-6'>
                         <label>Email <span class="text-danger">*</span></label>
                         <input className='form-control mt-2' type='email' placeholder='Enter Email' name="email" autoComplete='off' onChange={(e)=>handleEditChange(e)} value={editmodal.email}/>
                      
                     </div>
                     <div className='col-6'>
                         <label>Mobile # <span class="text-danger">*</span></label>
                         <input className='form-control mt-2' type='number' placeholder='Enter Mobile Number' name="mobile" autoComplete='off' onChange={(e)=>handleEditChange(e)} value={editmodal.mobile}/>
                     </div>
                 </div>
                 </Modal.Body>
                 <Modal.Footer>
                 <Button variant="danger" onClick={() => editAddress()}>
                         Submit
                     </Button>
                   <Button variant="primary" onClick={() => seteditmodal({ show: false })}>
                         Close
                     </Button>
                              
                 </Modal.Footer>
             </Modal>  
             <Modal show={modalState.show} onHide={() => onhide()} size='lg'>
 <Modal.Header closeButton>
                     <Modal.Title>
                   
         <h4 class="modal-title">Create Warehouse</h4>
       
    
                     </Modal.Title>
                 </Modal.Header>
                 <Modal.Body >
                 <div className='row'>
                     <div className='col-6'>
                         <label>Full Name <span class="text-danger">*</span></label>
                         <input className='form-control mt-2' type='text' placeholder='Enter Full Name' name="full_name" autoComplete='off' onChange={(e)=>handleChange(e)}/>
                     </div>
                  
                 </div>
                 <div className='row mt-3'>
                   
                     <div className='col-3'>
                    <label for="" class="form-label">Select State: <span class="text-danger">*</span></label>
           <select class="form-select"  name="state_name" onChange={(e)=>onChangeLocationstate(e)}>
             <option>Select</option>
             {stateList.map((item)=>{
             return (
               <option value={[item.state_id,item.country_id]}>{item.state_name}</option>
           )
           
 })}
          
           </select>
                     </div>
                     <div className='col-3'>
                     <label for="" class="form-label">Select City: <span class="text-danger">*</span></label>
                     <select class="form-select" name="city_name" onChange={(e)=>onChangeLocationcity(e)}>
             <option >Select</option>
             {cityList.map((item)=>{
             return (
               <option value={item.city_id}>{item.city_name}</option>
           )
           
 })}
           </select>
                     </div>
                     <div className='col-3'>
                     <label for="" class="form-label">Select Pincode: <span class="text-danger">*</span></label>
                  
      
       <Select
      
         options={options}
         value={selectedOption}
         onChange={(e)=>handleSelectChange(e)}
         isClearable={true}
         name="pincode"
       />
           
          
      
                     </div>

                 </div>

                 <div className='row mt-3'>
                     <div className='col-12'>
                         <label>Address <span class="text-danger">*</span></label>
                         <input className='form-control mt-2' placeholder='Enter Address' type='text' name="address" autoComplete='off' onChange={(e)=>handleChange(e)}/>
                     </div>
                 </div>
                 <div className='row mt-3'>
                     <div className='col-6'>
                         <label>Email <span class="text-danger">*</span></label>
                         <input className='form-control mt-2' type='email' placeholder='Enter Email' name="email" autoComplete='off' onChange={(e)=>handleChange(e)} />
                         {(addstate.err.email)?
                             <>
                             <i class="fa fa-exclamation-triangle" style={{color:"rgb(167, 37, 20)"}} aria-hidden="true"></i><small style={{color:"rgb(167, 37, 20)" ,fontSize: "0.75rem",
                             lineHeight: "1.66667",
                             fontWeight: "400",
                             letterSpacing: "0.05em",marginLeft:"4px"}}>Please Fill Valid Email</small></>:""}
                     </div>
                     <div className='col-6'>
                         <label>Mobile # <span class="text-danger">*</span></label>
                         <input className='form-control mt-2' type='number' placeholder='Enter Mobile Number' name="mobile" autoComplete='off' onChange={(e)=>handleChange(e)}/>
                     </div>
                 </div>
                 <hr/>
       <div class="p-3 text-center">
       {btnState==true?  <a  class="btn btn-primary" onClick={() => addAddress()}>Create Warehouse</a>:""}
       </div>
                 </Modal.Body>
               
            </Modal> 
    <div class="row mb-2">
        <div class="col-12 col-md">
            <div class="d-flex">
                <div class="back_btn_wrap">
                    <a href="" class="back_btn">
                    <CornerUpLeft/>
                    </a>
                </div>
                <div class="">
                    <h2>Warehouse</h2>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-5 text-end btns">
            <a  onClick={() => setModalState({ show: true })}class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#Warehouse_add">Create Warehouse</a>
        </div>
    </div>
    
    <div class="content-wrapper-data">
        <div class="card border-0">
          <div class="card-body">
                <div class="table-data table-responsive">
                    <table class="table table-hover table-responsive">
                        <thead>
                        <tr class="table-light">
                            <th>Warehouse ID</th>
                            <th>Warehouse Name</th>
                            <th>Address</th>
                            <th>City</th>
                            <th>Pincode</th>
                            <th>Mobile</th>
                            <th>Email</th>
                        </tr>
                        </thead>
                        <tbody>
                        {addressState.map((sub)=>(
                        <tr class="ship-now-container">
                            <td>
                                <h3><a href="#">#{sub.address_id}</a></h3>

                                {sub.primary==true?    <small><span class="badge rounded-pill bg-primary fw-400">Primary Address</span></small>:""}
                            </td>
                            <td>
                                <h4>{sub.warehouse_id}</h4>
                            </td>
                            <td>
                            <h4>{sub.address}</h4>
                            </td>
                            <td>
                                <h4>{sub.city_name}</h4>
                            </td>
                            <td class="channel_logo">
                                <h4>{sub.pincode}</h4>
                            </td>
                            <td>
                                <h4>{sub.mobile}</h4>
                            </td>
                            <td>
                                <h4>{sub.email}</h4>
                                
                                  <div class="custom-buttons button-container">
                                    <div class="d-flex justify-content-between">
                                        <div class="p-2 ship_now_2">
                                            <div class="mb-1">
                                                <a style={{cursor:"pointer"}} onClick={()=>Seteditaddress(sub)} class="btn btn-secondary btn-sm"data-bs-toggle="tooltip" title="Edit Warehouse">
                                                    <i class="fa fa-pencil" aria-hidden="true"></i>
                                                </a>
                                            </div>
                                            <div class="mb-1">
                                                <a onClick={()=>delete_address_book(sub.address_id)}  class="btn btn-secondary btn-sm"data-bs-toggle="tooltip" title="Delete Warehouse">
                                                    <i class="fa fa-ban" aria-hidden="true"></i>
                                                </a>
                                            </div>
                                            <div class="mb-1">
                                            {sub.primary==false?
                                                <a onClick={()=>setAsPrimary(sub.address_id)} class="btn btn-secondary btn-sm"data-bs-toggle="tooltip" title="Make Primary">
                                                    <i class="fa fa-star" aria-hidden="true"></i>
                                                </a>
                                                :""}
                                               
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    

    


    
</div>
{/* <!-- Main content area END --> */}
<Footer/>
</div>
  )
}

export default Customer_warehouse
