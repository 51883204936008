import React from 'react'
import Settings_nav from './Settings_nav'
import Left_menu from './Left_menu'
import Header from './Header'
import Create_order_nav from './Create_order_nav'
import axios from 'axios'
import queryString from 'query-string';
import { useNavigate,useLocation, useParams } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
import { useNetworkState } from 'react-use';

// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';


import Swal from 'sweetalert2'

import ReactLoading from 'react-loading';
import config from "../../config"
import Footer from '../Footer'
import { CornerLeftUp  , CornerUpLeft} from 'lucide-react'

const Settings_main = () => {
    const customer_id = useParams()
    console.log("customer_id",customer_id)
    const[infostate,setInfoState]= React.useState({customer_info:[],isLoading:true})
    const [state, setState] = React.useState({ isLoading: true, user_info: {} , country_id:"" , state_id:"" , city_id:"" });
    console.log("state",state)
    const [stateSave, setSaveState] = React.useState({ uploadimg: ""});
    const [isImageSelected, setIsImageSelected] = React.useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
    const [countryList, setcountryList] = React.useState([]);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
     React.useEffect(() => {
        getUsersInfo();
      }, []);

      

      const handleChange =(e)=>{
        console.log("e", e);
   
      let userTemp = {...state.user_info};
      userTemp[e.target.name] =  e.target.value;
       setState({ ...state, user_info:userTemp });
    
      }

  const axios_get_api = () => {
    let sendData = state.user_info
    console.log("sendData == " , sendData)
  if(state.user_info.full_name!="" && state.user_info.email!='' && state.user_info.address!=' ' && state.user_info.mobile!=""   ){
      
    axios.post(config.apiUrl + `/user/customer_update`,  sendData , { headers: config.headers }).then((result)=>{
              
               console.log("result=== " , result)
                if (result.data.status==true) {
                   
                     localStorage.setItem('ship_rocket_user', JSON.stringify(result.data.output[0]));
                    console.log("---------------------------");
                     
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 2000
                      })
                      
                      Toast.fire({
                        background:"#001b72",
                        type: 'Success',
                        title: "Successfully Update",
                        color:"white"
                      });
                    
                    window.location.reload();
                   
                }
             
                    // setaddState({web_body_id:"",body_heading:"",pic:"right",body_sub_heading:"",body_img:"",body_content:"",body_link_title:""})
                
                
                }).catch((e) => {
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'bottom-end',
                        showConfirmButton: false,
                        timer: 5000
                      })
                      
                      Toast.fire({
                        background:"#c2200a",
                        type: 'error',
                        title: "Something Went Wrong",
                        color:"white"
                      });
                 
                  console.log("----error:   ", e);
                })
            
            }
            else{
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 2000
                  })
                  
                  Toast.fire({
                    background:"#c2200a",
                    type: 'error',
                    title: "Please Fill the details",
                    color:"white"
                  });
            }
            
                

  };
       
  const getUsersInfo = () => {

    // setIsLoading(true)
    // let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/user_detail`;
    let sendData = {};

   
   // console.log("bb", sendData);
    axios.post(config.apiUrl + `/user/user_detail`,  sendData , { headers: config.headers }).then((res) => {
        // setIsLoading(false) 
        if(res.data.output.length ==1) {       
        setState({ ...state, user_info: res.data.output[0], isLoading: false })
        }



    }).catch((e) => {
        // setIsLoading(false)

    });
}
  return (
    <div>
     <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className="home-section">
          <Header/>
     <div class="row">
                    <div class="col-12 col-md-3 left_sub_menu_bx">
                        {/* <?php include 'settings_nav.php';?> */}
                        <Settings_nav value={1}/>
                    </div>
                <div class="col-12 col-md-9">
                        <div class="row mb-2">
                            <div class="col-12 col-md">
                                <div class="d-flex">
                                    <div class="back_btn_wrap">
                                        <a href="" class="back_btn">
                                        <CornerUpLeft/>
                                        </a>
                                    </div>
                                    <div class="">
                                        <h2>Profile</h2>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-5 text-end btns">

                            </div>
                        </div>

                        <div class="card border-0 shadow-sm">
                          <div class="card-body">
                            <div class="row">
                                    <div class="col-12 col-md-6 mb-3">
                                        <label for="" class="form-label">Name <span class="text-danger">*</span></label>
                                        <input type="text" class="form-control" name="full_name"  onChange={(e)=>handleChange(e)} required="" value={state.user_info.full_name}/>
                                    </div>
                                    <div class="col-12 col-md-6 mb-3">
                                        <label for="" class="form-label">Mobile Number <span class="text-danger">*</span></label>
                                        <input type="tel" class="form-control" name= "mobile"   onChange={(e)=>handleChange(e)} required="" value={state.user_info.mobile!=undefined && state.user_info.mobile!="" ? state.user_info.mobile:"" }/>
                                    </div>
                                    <div class="col-12 col-md-6 mb-3">
                                        <label for="" class="form-label">Email Address <span class="text-danger">*</span></label>
                                        <input type="text" class="form-control bg-light" name="email" onChange={(e)=>handleChange(e)} required="" value={state.user_info.email!=undefined && state.user_info.email!="" ? state.user_info.email:"" } readOnly/>
                                    </div>
                                    <div class="col-12 col-md-12 mb-3">
                                        <label for="" class="form-label">Full Address <span class="text-danger">*</span></label>
                                        <textarea class="form-control" rows="5" id="comment" name="address"  onChange={(e)=>handleChange(e)}  value={state.user_info.address!=undefined && state.user_info.address!=""? state.user_info.address:""}></textarea>
                                    </div>
                                    <div class="col-12 col-md-4 mb-3">
                                        <label for="" class="form-label">Country <span class="text-danger">*</span></label>
                                        <input type="text" class="form-control " required="" name="country"  onChange={(e) => handleChange(e)}value={state.user_info.country_name!=undefined && state.user_info.country_name!=""? state.user_info.country_name:""} />
                                    </div>
                                    <div class="col-12 col-md-4 mb-3">
                                        <label for="" class="form-label">State <span class="text-danger">*</span></label>
                                        <input type="text" class="form-control" required=""  onChange={(e) => handleChange(e)} name="state" value={state.user_info.state_name!=undefined && state.user_info.state_name!=""? state.user_info.state_name:""} />
                                    </div>
                                    <div class="col-12 col-md-4 mb-3">
                                        <label for="" class="form-label">City <span class="text-danger">*</span></label>
                                        <input type="text" class="form-control" required=""  onChange={(e) => handleChange(e)} name="city" value={state.user_info.city!=undefined && state.user_info.city!=""? state.user_info.city:""} />
                                    </div>
                            </div>

                            <a onClick={()=>axios_get_api()} class="btn btn-primary">Update</a>
                          </div>
                        </div>
                </div>
            </div>
            
            

                

        </div>
    
    <Footer/>

    </div>
  )
}

export default Settings_main
