import React from 'react'
import Left_menu from './Left_menu'
import Header from './Header'
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Form, Button, ProgressBar, ModalHeader, ModalTitle } from "react-bootstrap";
// import './customer_style.css';
import Modal from 'react-bootstrap/Modal';
import { Tooltip as ReactTooltip } from "react-tooltip";
import axios from "axios";
import Swal from 'sweetalert2'
import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import moment from 'react-moment';
import download from 'downloadjs'
import TablePagination from '@mui/material/TablePagination';
import { useNetworkState } from 'react-use';
import { Ban, Copy, CornerUpLeft , FileEdit, Package, PackageOpen, Plus, RefreshCcw, ShoppingBag, ShoppingBasket, Trash, Truck, Upload } from 'lucide-react';
import { List, arrayMove } from 'react-movable';
import Footer from '../Footer';

const Allocation = () => {
  let userDetail = localStorage.getItem('ship_rocket_user');
  // console.log("userDetail====", config.UserId);
  let userData = {};
  if (userDetail != null) {
    userData = JSON.parse(userDetail)
  }
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
  const[state,setState] = React.useState({})
  const[activestate,setactivestate] = React.useState(0)

  const[packagestate , setpackagestate] = React.useState([])
  console.log("packagestate ===" ,packagestate)
  const toggleSidebar = () => {
      setIsSidebarOpen(!isSidebarOpen);
      const homeSection = document.querySelector('.home-section');
      if (homeSection) {
          homeSection.classList.toggle('sidebar-closed');
      }
  };
  React.useEffect(()=>{
    get_customer_detail()
  },[])
  const get_customer_detail=()=>{
    let dataToSend = {customer_id : userData.customer_id};
   
     // console.log("datatoSend",dataToSend)
     let url = config.apiUrl + '/user/get_detail_customer';
     axios.post(url, dataToSend, { headers: config.headers })
         .then((res) => {
           // console.log("responseJson => ", res);
           if(res.data.status==true){
      setState(res.data.output)
      if(res.data.output.allocate==2){
        setactivestate(2)
        let dataToSend = {customer_id : userData.customer_id};
   
        // console.log("datatoSend",dataToSend)
        let url = config.apiUrl + '/user/get_customer_carriers';
        axios.post(url, dataToSend, { headers: config.headers })
            .then((res) => {
              // console.log("responseJson => ", res);
              if(res.data.status==true){
         setpackagestate(res.data.output)
        
         
       }
      })
      }
      else{
        setactivestate(res.data.output.allocate)
      }
      
    }
   else{
       const Toast = Swal.mixin({
           toast: true,
           position: 'bottom-end',
           showConfirmButton: false,
           timer: 3000
         })
         Toast.fire({
           background:"#c2200a",
           type: 'Unsuccess',
           title: "Something Went Wrong !",
           color:"white"
         });
   } })
       
         .catch((error) => {
           const Toast = Swal.mixin({
               toast: true,
               position: 'bottom-end',
               showConfirmButton: false,
               timer: 3000
             })
             Toast.fire({
               background:"#c2200a",
               type: 'Unsuccess',
               title: "Something Went Wrong !",
               color:"white"
             });
             // console.log(error);
         });    
   
   }

   const cheapestactive =()=>{
   setactivestate(1)
   setpackagestate([])
   }

   const customactive =()=>{
    setactivestate(2)

    let dataToSend = {customer_id : userData.customer_id};
   
    // console.log("datatoSend",dataToSend)
    let url = config.apiUrl + '/user/get_customer_carriers';
    axios.post(url, dataToSend, { headers: config.headers })
        .then((res) => {
          // console.log("responseJson => ", res);
          if(res.data.status==true){
     setpackagestate(res.data.output)
    
     
   }
  else{
      const Toast = Swal.mixin({
          toast: true,
          position: 'bottom-end',
          showConfirmButton: false,
          timer: 3000
        })
        Toast.fire({
          background:"#c2200a",
          type: 'Unsuccess',
          title: "Something Went Wrong !",
          color:"white"
        });
  } })
      
        .catch((error) => {
          const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 3000
            })
            Toast.fire({
              background:"#c2200a",
              type: 'Unsuccess',
              title: "Something Went Wrong !",
              color:"white"
            });
            // console.log(error);
        });  

    }

    const handleItemsReorder = (newOrder) => {
      setpackagestate(newOrder);
    };

    const save_settings=()=>{
      let dataToSend = {customer_id : userData.customer_id , allocate:activestate , carriers:packagestate};
     
       // console.log("datatoSend",dataToSend)
       let url = config.apiUrl + '/user/save_allocation_carriers';
       axios.post(url, dataToSend, { headers: config.headers })
           .then((res) => {
             // console.log("responseJson => ", res);
             if(res.data.status==true){
             get_customer_detail()

             const Toast = Swal.mixin({
              toast: true,
              position: 'bottom-end',
              showConfirmButton: false,
              timer: 3000
            })
            Toast.fire({
              background:"#001b72",
              type: 'Success',
              title: "Updated Successfully",
              color:"white"
            });
       
        
      }
     else{
         const Toast = Swal.mixin({
             toast: true,
             position: 'bottom-end',
             showConfirmButton: false,
             timer: 3000
           })
           Toast.fire({
             background:"#c2200a",
             type: 'Unsuccess',
             title: "Something Went Wrong !",
             color:"white"
           });
     } })
         
           .catch((error) => {
             const Toast = Swal.mixin({
                 toast: true,
                 position: 'bottom-end',
                 showConfirmButton: false,
                 timer: 3000
               })
               Toast.fire({
                 background:"#c2200a",
                 type: 'Unsuccess',
                 title: "Something Went Wrong !",
                 color:"white"
               });
               // console.log(error);
           });    
     
     }
  return (
    <div>
    <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className="home-section">
         
          <Header/>
        

        <section class="mb-3 pt-4">
          <div class="container">
            <div class="row mb-3">
              <div class="col-12 col-md">
                <h3 class="pt-3">Order Allocation Engine</h3>
                <nav aria-label="breadcrumb" class="">
                    <ol class="breadcrumb  ">
                      <li class="breadcrumb-item"><a href="./">Home</a></li>
                      <li class="breadcrumb-item " aria-current="page"><a href="tools.php">Tools</a></li>
                    </ol>
                </nav>
              </div>
              
              {/* <div class="col-12 col-md-4 text-end">
                <a href="/customer/allocation_rule" class="btn btn-primary btn-sm "><i class="fa fa-plus" aria-hidden="true"></i> Create New Shipping Rule</a>
              </div> */}
            </div>      
          </div>
        </section>


        <section class=" container mb-3">
          <div class=" allocation_bx_head">
            <div class="row justify-content-center py-3">
              <div class="col-12 col-md-6">
                <p class="">Please ensure that your search prioritization involves focusing primarily on identifying and selecting couriers.</p>
              </div>
            </div>
            
            <div class="row justify-content-center ">
              <div class="col-12 col-md-2" onClick={(e)=>cheapestactive()}>
               
                <div class="card" style={{ border: activestate === 1 ? "2px solid #0d6efd" : "" }}>
                  <div class="card-body">
                    <div class="icon_allo">
                      <img src="../../../img/loss.png" class="img-fluid" />
                    </div>
                    <h5>Cheapest</h5>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-2" onClick={(e)=>customactive()}>
                <div class="card" style={{ border: activestate === 2 ? "2px solid #0d6efd" : "" }}>
                  <div class="card-body">
                    <div class="icon_allo">
                      <img src="../../../img/custom.png" class="img-fluid" />
                    </div>
                    <h5>Custom</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>

          
          <section>
            <div class="row justify-content-center py-3">
              <div class="col-12 col-md-10">
                <div class="card my-3 text-center border-0">
                  <div class="card-body">
                  {activestate==2? <small>Drag and drop the couriers to create a customized priority based on your shipping needs.</small>:""}
                  </div>
                </div>

                 {/* <div class="allocation_bx_content">
                  <div class="card border-0 " style={{background: "#e4edf2",
    marginBottom: "4.5rem"}}>
                    <div class="card-body">
                      <div class="row justify-content-center">
                        {packagestate.map((sub)=>(
                          <div class="col-12 col-md-3 mb-3">
                            <div class="card box_holder" style={{background: "#fff",
    padding: "2rem 1rem",
    textAlign: "center",
    borderRadius: "10px",
    fontSize: "14px"}}>
                              <figure >
                                <img src={`https://cdn.shipbees.in/images/${sub.carrier_image}`} class="img-fluid" height="50px" width="50px" />
                              </figure>
                              <h6>{sub.carrier_name}</h6>
                             
                            </div>
                          </div>
                          ))}
                         
                      </div>
                    </div>
                  </div>
                </div>  */}

<div className="allocation_bx_content">
      <div className="card border-0" style={{ background: '#e4edf2', marginBottom: '4.5rem' }}>
        <div className="card-body">
          <List
            values={packagestate}
            onChange={({ oldIndex, newIndex }) => {
              setpackagestate(arrayMove(packagestate, oldIndex, newIndex));
            }}
            renderList={({ children, props }) => <div {...props}>{children}</div>}
            renderItem={({ value, props }) => (
              <div {...props} className="col-12 col-md-12 mb-3">
                <div
                  className="card box_holder handle"
                  style={{
                    background: '#fff',
                    padding: '2rem 1rem',
                    textAlign: 'center',
                    borderRadius: '10px',
                    fontSize: '14px',
                    
                  }}
                >
                  <figure>
                    <img src={`https://cdn.shipbees.in/images/${value.carrier_image}`} className="img-fluid" height="50px" width="50px" alt="Carrier" />
                  </figure>
                  <h6>{value.product_name}</h6>
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </div>
               

              </div>
            </div>

          </section>
          


          <div class="allocation_bx_footer">
            <div class="row justify-content-center py-3">
              <div class="col-12 col-md-4">
                <div class="d-grid">
                  <a onClick={(e)=>save_settings()} class="btn btn-primary btn-block">Save Settings</a>
                </div>
              </div>
            </div>
          </div>
          
        </section>


        
    </div> 
    <Footer/>
    </div>
  )
}

export default Allocation
