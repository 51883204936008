import React, {  useRef } from 'react'
import Header from './Header'
import Left_menu from './Left_menu'
import { useNavigate, useParams } from 'react-router-dom';
import axios from "axios";
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
// import './customer_style.css';
import Barcode from 'react-barcode';
import Modal from 'react-bootstrap/Modal';
// import { useLocation } from 'react-router-dom';
import ReactLoading from 'react-loading';
import config from "../../config"
import download from 'downloadjs'
import Moment from 'react-moment';
import TablePagination from '@mui/material/TablePagination';
import htmlToPdfmake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Chart from 'chart.js/auto';

import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment';
import html2canvas from 'html2canvas';
import BarcodeComponent from './BacodeComponent'
import { renderToString } from 'react-dom/server';
import createRoot from 'react-dom';
import { useNetworkState } from 'react-use';
import { BarcodeIcon, CornerUpLeft, CornerUpLeftIcon, FileDown, Truck } from 'lucide-react';
import Footer from '../Footer';
const Product_detail = () => {
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====shipments====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
    let {product_id} = useParams();
    const[state , setState] = React.useState([])
    console.log("state === " , state)
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
    const [totalStock, setTotalStock] = React.useState(0);
    const [stockentrystate, setstockentrystate] = React.useState([]);
    const [warehousestate, setwarehouseState] = React.useState([]);

    const [chartData, setChartData] = React.useState(null);
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };

    React.useEffect(()=>{
        product_detail_listing()
        stock_entries_ofcustomers()
        product_detail_listing_with_warehouse()
    },[])
    const product_detail_listing = ()=>{
      
        let full_api = config.apiUrl + `/user/product_detail`;
        let sendData = {product_id:product_id};
      
        axios.post(full_api, sendData, { headers: config.headers }).then(res => {
            console.log("response == " , res.data.output)
           setState(res.data.output)
           const variantsArray = res.data.output.map(item => item.variants);
           console.log("Variants array: ", variantsArray);

        
        }).catch((e) => {
          // toast.configure()
           //toast.error("Some thing went wrong")
          // console.log("----error:   ", e);
        })
       }

       const product_detail_listing_with_warehouse = ()=>{
      
        let full_api = config.apiUrl + `/user/product_detail_with_warehouse`;
        let sendData = {product_id:product_id};
      
        axios.post(full_api, sendData, { headers: config.headers }).then(res => {
            console.log("response---- == " , res.data.output)
            setwarehouseState(res.data.output.stock)
            
          
        
        }).catch((e) => {
          // toast.configure()
           //toast.error("Some thing went wrong")
          // console.log("----error:   ", e);
        })
       }

       const stock_entries_ofcustomers = (e)=>{
       
        let full_api = config.apiUrl + `/user/stock_entries_of_customers`;
        let sendData = {product_id:product_id};
      
        axios.post(full_api, sendData, { headers: config.headers }).then(res => {
            setstockentrystate(res.data.output)
        }).catch((e) => {
          // toast.configure()
           //toast.error("Some thing went wrong")
          // console.log("----error:   ", e);
        })
       }

       React.useEffect(() => {
        const fetchData = async () => {
            const full_api = config.apiUrl + `/user/productStockData`;
            const sendData = { product_id: product_id };

            try {
                const response = await axios.post(full_api, sendData, { headers: config.headers });
                setChartData(response.data);
            } catch (error) {
                console.error("Error fetching product stock data:", error);
            }
        };

        fetchData();
    }, []);

    React.useEffect(() => {
        if (chartData) {
            const canvas = document.getElementById('productStockChart');
            if (canvas) {
                const ctx = canvas.getContext('2d');
                var productStockChart = new Chart(ctx, {
                    type: 'line',
                    data: chartData,
                    options: {
                        responsive: true,
                        title: {
                            display: true,
                            text: 'Product Stock in Different Warehouses'
                        },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    beginAtZero: true
                                }
                            }]
                        }
                    }
                });
                return () => {
                    productStockChart.destroy();
                };
            }
        }
    }, [chartData]);

      
  return (
    <div>
        <Left_menu isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <div className="home-section">
         
          <Header/>
            
{state.map((sub)=>(
            <div class="row mb-2">
                <div class="col-12 col-md">
                    <div class="d-flex">
                        <div class="back_btn_wrap">
                            <a href="" class="back_btn">
                           <CornerUpLeft/>
                            </a>
                        </div>
                        <div class="">
                            <h2>{sub.product_name}</h2>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-5 text-end btns">
                <h4>SKU: {sub.sku_code}</h4>
                </div>
            </div>
            ))}

            {state.map((sub)=>(
            <div class="row my-3">
                <div class="col-12 col-md">
                    <div class="card border-0 shadow-sm">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 col-md-4">
                                    <img src={"https://cdn.shipbees.in/customer_assets/"+sub.product_image}  class="img-fluid img-thumbnail" />
                                </div>
                                <div class="col-12 col-md">
                                    <div class="row">
                                        <div class="col-12 col-md-9 mb-2">
                                            <small class="text-muted">Product Name</small>
                                            <h5>{sub.product_name}</h5>
                                        </div>
                                        <div class="col-12 col-md-3 mb-2">
                                            <small class="text-muted">{sub.created}</small>
                                        </div>
                                        <div class="col-12 col-md-6 mb-2">
                                            <small class="text-muted">Variants</small>
                                            {sub.variants.map((item)=>(<p>{item.value}</p>))}
                                        </div>
                                        <div class="col-12 col-md-6 mb-2">
                                            <small class="text-muted">Category</small>
                                            <p>{sub.category_name}</p>
                                        </div>
                                        <div class="col-12 col-md-6 mb-2">
                                            <small class="text-muted">SKU Code</small>
                                            <p>{sub.sku_code}</p>
                                        </div>
                                        
                                        <div class="col-12 col-md-6 mb-2">
                                            <small class="text-muted">Value</small>
                                            <p>₹ {sub.price}</p>
                                        </div>
                                        <div class="col-12 col-md-6 mb-2">
                                            <small class="text-muted">Weight</small>
                                            <p>{sub.weight}KG</p>
                                        </div>
                                        <div class="col-12 col-md-6 mb-2">
                                            <small class="text-muted">Dimensions</small>
                                            <p>{sub.length}x{sub.width}x{sub.height}</p>
                                        </div>
                                        {/* <div class="col-12 col-md-6 mb-2">
                                            <small class="text-muted">Total Stock</small>
                                            <p>{totalStock}</p>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="card border-0 shadow-sm">
                      <div class="card-body">
                        <canvas id="productStockChart" width="400" height="400"></canvas>
                      </div>
                    </div>
                </div>
            </div>))}
            
            


           <section class="pb-3">
            <ul class="nav nav-tabs">
                <li class="nav-item">
                    <a class="nav-link active" data-bs-toggle="tab" href="#home">Recent Stock Entries</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" data-bs-toggle="tab" href="#menu1">Available Stock</a>
                </li>
            </ul>

            {/* <!-- Tab panes --> */}
            <div class="tab-content">
            <div class="tab-pane container active" id="home">
                <div class="content-wrapper-data">
                    <div class="card border-0">
                    <div class="card-body">
                            {/* <div class="row mb-3 justify-content-end">
                                <div class="col-12 col-md-3">
                                    <select class="form-select">
                                      <option>All Warehouse</option>
                                      <option>Gurugram Warehouse</option>
                                      <option>Jaipur Warehouse</option>
                                      <option>Delhi Warehouse</option>
                                    </select>
                                </div>
                            </div> */}
                            <div class="table-data table-responsive">
                                <table class="table table-hover table-responsive">
                                    <thead>
                                    <tr class="table-light">
                                        <th>Date</th>
                                        <th>SKU #</th>
                                        <th>Product Name</th>
                                        <th>Warehouse </th>
                                        <th>Customer Details</th>
                                        <th>Vendor Added Units</th>
                                        <th>Approved Units</th>
                                       
                                        <th>Mode</th>
                                        <th>Status</th>
                                        <th>Remark</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        {stockentrystate.map((sub)=>(
                                        <tr class="ship-now-container">
                                            <td>
                                                <h6><Moment format="MMM DD YYYY">
                         {new Date(sub.created*1000)}
             </Moment> - <Moment format="hh:mm:ss a">
                         {new Date(sub.created*1000)}
             </Moment></h6>
                                            </td>
                                            <td>
                                                <h3><a href={`/customer/product_detail/${sub.product_id}`}>#{sub.product_detail.sku_code}</a></h3>
                                            </td>
                                            <td>
                                                <h4><a href="product_detail.php">{sub.product_detail.product_name}</a></h4>
                                                {sub.product_detail.variants.map((sub)=>( <h6>{sub.value}</h6>))}
                                            </td>
                                            <td>
                                            {sub.warehouse_name}
                                            </td>
                                            <td>
                                                <h4><a href="">{userData.full_name}</a></h4>
                                                <small>Account #{userData.customer_id}</small>
                                            </td>
                                            <td>
                                                <h4>{sub.vendor_added_units}</h4>
                                            </td>
                                            <td>
                                                <h4>{sub.approved_units}</h4>
                                            </td>
                                           
                                            <td>
                                               {sub.mode==1? <small><span class="badge rounded-pill bg-success fw-400">In Bound</span></small>:  <small><span class="badge rounded-pill bg-danger fw-400">Out Bound</span></small>}
                                            </td>
                                            <td>
                                                {sub.status==0? <small><span class="badge rounded-pill bg-warning fw-400">Pending</span></small>:sub.status==1? <small><span class="badge rounded-pill bg-success fw-400">Confirmed</span></small>: <small><span class="badge rounded-pill bg-danger fw-400">Rejected</span></small>}
                                               
                                            </td>
                                            <td>{sub.remark}</td>
                                        </tr>
                                        ))}
                                       
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tab-pane container fade" id="menu1">
            <div class="content-wrapper-data">
                    <div class="card border-0">
                        <div class="card-body">
                            {/* <div class="row mb-3 justify-content-end">
                                <div class="col-12 col-md-3">
                                    <select class="form-select">
                                      <option>All Warehouse</option>
                                      <option>Gurugram Warehouse</option>
                                      <option>Jaipur Warehouse</option>
                                      <option>Delhi Warehouse</option>
                                    </select>
                                </div>
                            </div> */}
                            <div class="table-data">
                            {warehousestate.map((sub)=>(
                                <div class="card bg-light border-0 shadow-sm mb-3">
                                   
                                  <div class="card-body">
                                    <h2 class="mb-3">{sub.warehouse_name.warehouse_name}</h2>
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                            <th>Variants</th>
                                            <th>Item Qty</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sub.variant.map((item)=>(
                                            <tr>
                                            <td>{item.value}</td>
                                            <td>{item.stock}</td>
                                            </tr>
                                           ))}
                                            <tr>
                                            <td>Total Stock</td>
                                            <td>
            {sub.variant.reduce((total, item) => total + item.stock, 0)}
        </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                  </div>
                                 
                                </div>
                                 ))}
                                <hr/>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
           </section>

            
<section>
                    <div class="offcanvas offcanvas-end " id="order_details">
                        <div class="offcanvas-header">
                            <div class="offcanvas-title">
                                <div class="row mb-2">
                                    <div class="col-12 col-md">
                                     <h2><small>Order #</small>298239842348</h2>
                                    </div>
                                    <div class="col-12 col-md-2 text-end">
                                        
                                    </div>
                                    <div class="col-12 col-md-4 text-end btns">
                                        <a href="#" class="btn btn-secondary-o btn-sm" data-bs-toggle="tooltip" title="Edit Order"><i data-lucide="file-edit"></i></a>
                                        <a href="#" class="btn btn-secondary-o btn-sm" data-bs-toggle="tooltip" title="Clone Order"><i data-lucide="copy"></i></a>
                                        <a href="#" class="btn btn-secondary-o btn-sm" data-bs-toggle="tooltip" title="Cancel Order"><i data-lucide="ban"></i></a>
                                    </div>
                                </div>
                            </div>
                            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas"></button>
                        </div>
                        <div class="offcanvas-body">


                            <div class="">
                                <div class="row mt-3 order_box_cont">
                                    <div class="col-12 col-md-12 ">
                                        <div class="card border-0 mb-3">
                                            <div class="card-body">
                                                <div class="d-flex ">
                                                    <div class="border-end pe-3">
                                                    <i data-lucide="shopping-bag"></i>
                                                    </div>
                                                    <div class="ps-3 ">
                                                        <h6>Order Details</h6>
                                                    </div>
                                                </div>
                                                <hr/>
                                                <div class="row">
                                                    <div class="col-12 col-md-6 mb-2">
                                                        <small class="text-muted">Order created on channel</small>
                                                        <p>11 Jul 2023 12:00 AM</p>
                                                    </div>
                                                    <div class="col-12 col-md-6 mb-2">
                                                        <small class="text-muted">Order created on system</small>
                                                        <p>11 Jul 2023 03:41 PM</p>
                                                    </div>
                                                    <div class="col-12 col-md-6 mb-2">
                                                        <small class="text-muted">Channel</small>
                                                        <p>IndoChina Store - Woocommerce</p>
                                                    </div>
                                                    <div class="col-12 col-md-6 mb-2">
                                                        <small class="text-muted">Shipment value</small>
                                                        <p>₹ 2000 <span class="badge rounded-pill bg-warning">Prepaid</span></p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="card border-0 mb-3">
                                        <div class="card-body">
                                            <div class="d-flex ">
                                                <div class="border-end pe-3">
                                                <i data-lucide="package"></i>
                                                </div>
                                                <div class="ps-3 ">
                                                    <h6>Shipment Details</h6>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div class="row">
                                                <div class="col-12 col-md">
                                                    <h6>Ship From:</h6>
                                                        <small class="text-muted">Sender Name:</small>
                                                        <p>Rahul Gandi</p>
                                                        <small class="text-muted">Sender Address:</small>
                                                        <p>Lodhiya Street, New Delhi, Delhi 110001</p>
                                                        <small class="text-muted">Sender Email:</small>
                                                        <p>rahulgandi@gmail.com</p>
                                                        <small class="text-muted">Sender Mobile:</small>
                                                        <p>123456789</p>
                                                </div>
                                                <div class="col-12 col-md">
                                                    <h6>Ship To:</h6>
                                                        <small class="text-muted">Receiver Name:</small>
                                                        <p>Rahul Gandi</p>
                                                        <small class="text-muted">Receiver Address:</small>
                                                        <p>Lodhiya Street, New Delhi, Delhi 110001</p>
                                                        <small class="text-muted">Receiver Email:</small>
                                                        <p>rahulgandi@gmail.com</p>
                                                        <small class="text-muted">Receiver Mobile:</small>
                                                        <p>123456789</p>
                                                </div>
                                            </div>
                                        </div>
                                        </div>



                                        <div class="card border-0  mb-3">
                                            <div class="card-body">
                                            <div class="d-flex ">
                                                <div class="border-end pe-3">
                                                <i data-lucide="package-open"></i>
                                                </div>
                                                <div class="ps-3 ">
                                                    <h6>Package Details</h6>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div class="row">
                                                <div class="col-12 col-md-4 mb-2">
                                                    <small class="text-muted">Applicable Weight (in Kg)</small>
                                                    <p>1KG</p>
                                                </div>
                                                <div class="col-12 col-md-4 mb-2">
                                                    <small class="text-muted">Dead Weight (in Kg)</small>
                                                    <p>1KG</p>
                                                </div>
                                                <div class="col-12 col-md-4 mb-2">
                                                    <small class="text-muted">Volumetric Weight (in kg)</small>
                                                    <p>1KG</p>
                                                </div>
                                                <div class="col-12 col-md-4 mb-2">
                                                    <small class="text-muted">Dimensions (in cm)</small>
                                                    <p>1.000 x 1.000 x 1.000</p>
                                                </div>
                                                <div class="col-12 col-md-6 mb-2">
                                                    <small class="text-muted">Number of Boxes</small>
                                                    <p>1</p>
                                                </div>
                                            </div>
                                            </div>
                                        </div>

                                        <div class="card border-0 mb-3">
                                            <div class="card-body">
                                            <div class="d-flex ">
                                                <div class="border-end pe-3">
                                                <i data-lucide="shopping-basket"></i>
                                                </div>
                                                <div class="ps-3 ">
                                                    <h6>Product Details</h6>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div class="table-responsive ">
                                                <table class="table table-light">
                                                    <thead>
                                                    <tr>
                                                        <th>Name</th>
                                                        <th>Category</th>
                                                        <th>HSN</th>
                                                        <th>SKU</th>
                                                        <th>Qty</th>
                                                        <th>Unit price</th>
                                                        <th>Discount</th>
                                                        <th>Tax</th>
                                                        <th>Total</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <td>Samsung mobile Transparent Covers - Samsung-A50</td>
                                                        <td>1689070274414</td>
                                                        <td>samsung-A50</td>
                                                        <td>1</td>
                                                        <td>1</td>
                                                        <td>200.00</td>
                                                        <td>100.00</td>
                                                        <td>10.00</td>
                                                        <td>100.00</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            </div>
                                        </div>

                                        
                                        
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                

        </div>
        <Footer/>
    </div>
  )
}

export default Product_detail
